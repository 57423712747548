export default {
  colors: {
    green: '#2AD488',
    blue: '#02CCE6',
    navy: '#192835',
    white: '#FFFFFF',

  },
  breakpoints: {
    large: '1024',
    extraLarge: '1600px'
  },
  fonts: {
    nexaBold: 'nexa_bold',
    nexaLight: 'nexa_light'
  },
  componentHeights: {
    footer: '130',
    nav: '70'
  }
}